























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VariableTextModel from '@/models/configurator/variables/variable-text.model';
import NotificationModel from '@/models/notification.model';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';

@Component({})
export default class ViewerVariableText extends Vue {
  @Prop()
  readonly variables!: VariableTextModel[];

  dialog = false;

  filter = '';

  displayVariables: VariableTextModel[] = [];

  async copy(variable: string): Promise<void> {
    await navigator.clipboard.writeText(variable);
    this.dialog = false;
    this.$notify.show(new NotificationModel('Copied!', NotificationTypeEnum.Info));
  }

  doFilter(): void {
    this.displayVariables = this.variables.filter(
      (f) => this.hasFilter(f.variableName) || this.hasFilter(f.description),
    );
  }

  hasFilter(text: string): boolean {
    return text.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
  }

  mounted(): void {
    this.displayVariables = this.variables;
  }
}
